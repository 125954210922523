import {Canvas, useFrame} from '@react-three/fiber'
import {BakeShadows, MeshReflectorMaterial} from '@react-three/drei'
import {Bloom, DepthOfField, EffectComposer} from '@react-three/postprocessing'
import {easing} from 'maath'
import {Computers, Instances} from './Computers.tsx'

export default function ComputersCanvas() {
  return (
    <Canvas dpr={[1, 1.5]} camera={{ position: [-1.5, 1, 5.5], fov: 50, near: 1}} eventSource={document.getElementById('app')!} eventPrefix="client">
      {/* Lights */}
      <color attach="background" args={['black']} />
      <hemisphereLight intensity={0.15} groundColor="black" />
      <spotLight decay={0} position={[10, 20, 10]} angle={0.12} penumbra={1} intensity={1} castShadow shadow-mapSize={1024} />
      {/* Main scene */}
      <group position={[-0, -1, 0]}>
        {/* Auto-instanced sketchfab model */}
        <Instances>
          <Computers scale={0.5} />
        </Instances>
        {/* Plane reflections + distance blur */}
        <mesh receiveShadow rotation={[-Math.PI / 2, 0, 0]}>
          <planeGeometry args={[50, 50]} />
          <MeshReflectorMaterial
            blur={[300, 30]}
            resolution={2048}
            mixBlur={1}
            mixStrength={180}
            roughness={1}
            depthScale={1.2}
            minDepthThreshold={0.4}
            maxDepthThreshold={1.4}
            color="#202020"
            metalness={0.8}
            mirror={0}
          />
        </mesh>
      </group>
      {/* Postprocessing */}
      <EffectComposer enableNormalPass={false}>
        <Bloom luminanceThreshold={0} mipmapBlur luminanceSmoothing={0.0} intensity={5} />
        <DepthOfField target={[0, 0, 13]} focalLength={0.3} bokehScale={15} height={700} />
      </EffectComposer>
      {/* Camera movements */}
      <CameraRig />
      {/* Small helper that freezes the shadows for better performance */}
      <BakeShadows />
    </Canvas>
  )
}

function CameraRig() {
  useFrame((state, delta) => {
    // Calculate a multiplier based on the viewport width
    const widthMultiplier = Math.max(1, 2 - state.viewport.width / 5);

    // Adjust the z-position based on the multiplier
    const targetZ = 5.5 * widthMultiplier;

    easing.damp3(
      state.camera.position,
      [
        -1 + (state.pointer.x * state.viewport.width) / 3,
        (1 + state.pointer.y) / 2,
        targetZ  // Use the adjusted z-position
      ],
      0.5,
      delta
    )
    state.camera.lookAt(0, 0, 0)
  })
}
