import { createFileRoute } from '@tanstack/react-router'
import { ComputersOverlay } from "./-components/ComputersOverlay.tsx";
import ComputersCanvas from "./-components/ComputersCanvas.tsx";

export const Route = createFileRoute('/')({
  component: Index,
})

function Index() {
  return (
    <div className="h-svh">
      <ComputersOverlay />
      <ComputersCanvas />
    </div>
  )
}
